











































import Vue from "vue";
import Footer from "../components/footer/Footer.vue"
import emailjs from 'emailjs-com';

export default Vue.extend({
  name: "Contact",
  components: {
    Footer
  },
  data(){
    return{
      text: '' as string,
      from: '' as string,
      name:'' as string,
      isBusy: false
    }
  },
  computed:{
    isValid(){
      const validEmail = /\S+@\S+\.\S+/.test(this.from);

      if(this.text.length > 0 && this.from.length > 0 && this.name.length > 0 && validEmail)
        return true;
      else 
        return false;
    }
  },
  methods:{
    send(){
      this.isBusy = true;
      emailjs.send('service_voro03g', 'template_y9x7llg',
      {
        "from_name": this.name,
        "from_email": this.from,
        "message": this.text
      },'user_PJugDKE4fDaTBia19jBK5').then(()=>{
        this.name = ''
        this.from = ''
        this.text = ''
      })
      .catch((error)=>{
        console.log({error})
      })
      .finally(()=>{
        this.isBusy = false
      })
    }
  }
});
